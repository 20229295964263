module.exports = {
  // OAuth info (copied from the FusionAuth admin panel)
  clientID: 'b4f122f7-f06a-465f-ba19-2419f75dd6b9',
  clientSecret: 'JXkS9Q7iFKsgodz2yX0o06AgMQBIIJUD4VSmZT43EA4',
  redirectURI: 'http://localhost:3001/oauth-callback',
  applicationID: 'b4f122f7-f06a-465f-ba19-2419f75dd6b9',

  // our FusionAuth api key
  apiKey: 'OvMO0F4ny06rRFwcQYasTEvw0aDZc98XCEawvvStAS2DM9i0OvIs1iiI',

  // ports
  clientPort: 3000,
  serverPort: 3001,
  fusionAuthPort: 9011
};

import React from 'react';
import {
  Banner,
  Brand,
  Card,
  CardBody,
  CardHeader,
  CardHeaderMain,
  CardTitle,
  Title
} from '@patternfly/react-core';

import parksLogo from "./eht_parks.png";

class Forms extends React.Component {

  render() {
    return (
      <div>
      	<Banner variant="info">Forms and Documents</Banner>
          <Title headingLevel="h1" size="2x1">
            <Card isSelectable key="background_process_card">
	      <CardHeader>
	        <Brand src={parksLogo} alt="EHT Parks and Recreation" height="100px" width="100px" />
	      </CardHeader>
              <CardTitle><u>Coaching Background Check - Process and Guidelines</u></CardTitle>
                <CardBody>
                  <a href="https://drive.google.com/file/d/1XPpUDpbl37iTnc0tYy2mQpIWwSElk9ib/view?usp=drive_link" target="_blank" rel="noreferrer">Process and Guidelines</a>
                </CardBody>
            </Card>
	  </Title>
      </div>
    );
  }
}

export default Forms;

